import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import "firebase/compat/messaging";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIB_API_KEY,
	authDomain: process.env.REACT_APP_FIB_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIB_APP_ID,
	databaseURL: process.env.REACT_APP_FIB_DATABASE,
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const database = firebase.database();

const generateToken = async () => {
	const token = await messaging.getToken({
		vapidKey: process.env.REACT_APP_VAPID_KEY
	}).catch((error) => {
		console.error("Error generating token:", error);
	});
	return token;
}

export {
	auth,
	googleAuthProvider,
	githubAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider,
	database,
	firebase,
	generateToken,
	messaging
};

