const dbName = "SRA_DEVICE";
const storeName = "device";
const dbVersion = 1;

export async function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "key" });
      }
    };
    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
}

export async function storeDeviceId(deviceId) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.put({ key: "device_id", value: deviceId });
    request.onsuccess = () => resolve();
    request.onerror = (event) => reject(event.target.error);
  });
}

export async function getDeviceId() {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get("device_id");
    request.onsuccess = (event) => {
      const result = event.target.result;
      resolve(result ? result.value : null);
    };
    request.onerror = (event) => reject(event.target.error);
  });
}

export function generateDeviceId() {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return `device-${array[0].toString(36)}`;
}
