// ** UseJWT import to get config
import { AxiosInstance, unsubscribeNotification } from 'util/index';
import { NotificationManager } from 'react-notifications';
import { firebase, auth } from "./../../../firebase";
import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER
} from './../Types';

// ** Handle User Login
function loginUserSuccess(dispatch, user, history) {
  dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: user
  });

  window.location.href = "/app/welcome"  // history.push('/app/dashboard');
}

function loginUserFailure(dispatch, error) {
  dispatch({
    type: LOGIN_USER_FAILURE,
    payload: error
  });
  NotificationManager.error(error.message);
}

export const handleLogin = (data, props, phone, deviceDetails) => {
  return (dispatch) => {
    AxiosInstance.post(`/auth/loginUser`, {
      mobile_number: data.mobile_number,
      password: data.password,
      fcm_token: deviceDetails?.fcmToken,
      device_id: deviceDetails?.deviceId,
    })
      .then((response) => {
        if (response && response.data && response.data.status === true) {
          if (response && response.data && (response.data.data.user_type === "Admin" || response.data.data.user_type === "Staff" || response.data.data.user_type === "SuperAdmin")) {
            localStorage.setItem("authentication", response.data.authentication)
            firebase
              .auth()
              .signInWithCustomToken(response.data.data.token)
              .then((userCredential) => {
                localStorage.setItem("loginId", response.data.data._id);
                localStorage.setItem("userData", JSON.stringify(response.data.data));
                localStorage.setItem("user_id", JSON.stringify(response.data.data._id));
                localStorage.setItem("last_login", JSON.stringify(response.data.data.last_login));
                loginUserSuccess(dispatch, localStorage.getItem("user_id"), props.history);
                NotificationManager.success(response.data.message);
              })
              .catch((error) => {
                loginUserFailure(dispatch, error);
                NotificationManager.error("Something Went Wong !");
              });
          } else {
            loginUserFailure(dispatch, "You don't have an access of web panel");
            NotificationManager.error("You don't have an access of web panel");
          }

        } else {

          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });
  };
};

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    unsubscribeNotification();
    localStorage.removeItem("userData");
    window.localStorage.clear();
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("userData");
        localStorage.removeItem("user_id");
        dispatch({ type: LOGOUT_USER });
        NotificationManager.success("User logout successfully!");
      })
      .catch((error) => {
        NotificationManager.error("Something Went Wrong !");
      });
  };
};

export const handleForgotPassword = (props, data, phone) => {
  return async (dispatch) => {
    AxiosInstance
      .post(`/auth/checkMobileNumberExist`, {
        mobile_number: data.mobileNumber,
        is_generate_custom_token: process.env.REACT_APP_IS_OTP_SEND === "true" ? true : false
      })
      .then((response) => {
        if (response.data.data !== null) {
          if (process.env.REACT_APP_IS_OTP_SEND === "true") { // for custom otp
            AxiosInstance.post('/auth/send-otp', {
              app_type: "dashboard_panel",
              userId: response?.data?.data?._id,
              mobile_number: data.mobileNumber
            }).then((res) => {
              NotificationManager.success(`OTP has been sent to your number: ${data.mobileNumber}`);

              props.history.push({
                pathname: '/otp', state: {
                  id: response.data.data._id,
                  mobile: data.mobileNumber,
                  status: true,
                  token: response.data.data.customToken
                },
              });
            })
          } else {
            const verify = new firebase.auth.RecaptchaVerifier(
              "recaptcha-container",
              {
                size: "invisible",
                badge: "inline",
              }
            );
            auth
              .signInWithPhoneNumber(data.mobileNumber, verify)
              .then((result) => {
                window.finalResult = result;
                props.history.push({
                  pathname: "/otp",
                  state: {
                    id: response && response.data && response.data.data && response.data.data._id,
                    mobile: data.mobileNumber,
                    status: true,
                    type_pass: "set"
                  }
                });
                NotificationManager.success(`OTP has been sent to your number: ${data.mobileNumber}`);
              })
              .catch((err) => {
                NotificationManager.error("Something Went Wrong !");

                window.location.reload();
              });
          }
        } else {
          NotificationManager.error("Mobile dose not registered");
        }
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });
  }
};

export const verifyOtp = async (data, props) => {
  if (data.login_otp === null) return;

  if (process.env.REACT_APP_IS_OTP_SEND === "true") {
    try {
      const result = await AxiosInstance.post("auth/verify", {
        app_type: "dashboard_panel",
        userId: props.location.state.id,
        otp: data.login_otp,
      });

      if (result.data) {
        props.history.push({
          pathname: "/setNewPassword",
          state: { id: props.location.state.id, status: true, type_pass: props.location.state.type_pass },
        });

        await auth.signInWithCustomToken(props.location.state.token);
        NotificationManager.success(result.data.message);
      }
    } catch (error) {
      NotificationManager.error(error.response.data.message ? error.response.data.message : "Something went wrong !")
    }
  } else {
    window.finalResult.confirm(data.login_otp).then(async (result) => {
      if (result) {
        props.history.push({
          pathname: "/setNewPassword",
          state: { id: props.location.state.id, status: true, type_pass: props.location.state.type_pass },
        });
        NotificationManager.success("Otp verify successfully ");
      }
    }).catch((err) => {
      NotificationManager.error("OTP did't match please enter latest OTP!");
    });

    return (dispatch) => {
      dispatch({ type: "Otp", data });
    };
  }
};

export const CreatePassword = (data, props) => {
  return async (dispatch) => {
    try {
      const response = await AxiosInstance.post("/auth/setNewPassword", {
        id: props && props.location && props.location.state.id,
        password: data.password,
        uuid: auth.currentUser.uid !== null ? auth.currentUser.uid : null
      });
      NotificationManager.success(response.data.message);
      dispatch({ type: "CHANGE_PASSWORD" });
      props.history.push("/signin");
    } catch (error) {
      NotificationManager.error("Something Went Wrong !");
      dispatch({ type: "CHANGE_PASSWORD" });
    }
  };
};

export const checkMobileNumberExist = (data, props) => {
  return async (dispatch) => {
    AxiosInstance.post('/auth/checkMobileNumberExist', {
      mobile_number: data.mobile_number,
      is_generate_custom_token: process.env.REACT_APP_IS_OTP_SEND === "true" ? true : false
    }).then(response => {
      if (response && response.data && response.data.status === true) {
        if (response && response.data && (response.data.data.user_type === "Admin" || response.data.data.user_type === "Staff" || response.data.data.user_type === "SuperAdmin")) {
          if (response && response.data && response.data.data.is_password_exist === false) {

            if (process.env.REACT_APP_IS_OTP_SEND === "true") { // for custom otp
              AxiosInstance.post('/auth/send-otp', {
                app_type: "dashboard_panel",
                userId: response?.data?.data?._id,
                mobile_number: data.mobile_number
              }).then((res) => {
                NotificationManager.success(`OTP has been sent to your number: ${data.mobile_number}`);

                props.history.push({
                  pathname: '/otp', state: {
                    id: response.data.data._id,
                    mobile: data.mobile_number,
                    status: true,
                    token: response.data.data.customToken
                  },
                });
              })
            } else {
              const verify = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
                size: 'invisible',
                badge: 'inline'
              });
              auth.signInWithPhoneNumber(data.mobile_number, verify).then((result) => {
                window.finalResult = result;
                props.history.push({
                  pathname: '/otp', state: {
                    id: response && response.data && response.data.data && response.data.data._id,
                    mobile: data.mobile_number,
                    status: true,
                    type_pass: "set"
                  }
                })

                NotificationManager.success(`OTP has been sent to your number: ${data.mobile_number}`);
              }).catch((error) => {
                NotificationManager.error("Something went wrong!");
              });
            }
          } else {
            dispatch({ type: 'SET_IS_PASSWORD_EXIST', isPasswordExist: true }); // update isPasswordExist state to true
          }
        } else {
          NotificationManager.error("You don't have access web panel");
        }
      } else {
        NotificationManager.error(response && response.data && response.data.data.message);
      }

    })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });
  }
}

export const setDevice = (data) => ({
  type: "SET_DEVICE",
  data
});