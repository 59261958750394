import { NotificationManager } from 'react-notifications';
import { Axios } from '../../../util';

const userData = JSON.parse(localStorage.getItem("userData"))
const permission = userData ? userData.authentication : [];

export const getApplicationsList = (params, setIsLoader) => {
    setIsLoader(true)
    return dispatch => {
        let url = `/api/land-acquisition/paginated/la-applications-list?page=${params.page}&limit=${params.perPage}`;

        if (params.search && params.search.trim() !== "") {
            url += `&search=${encodeURIComponent(params.search.trim())}`;
        }
        if (params.ward_id && params.ward_id !== "undefined") {
            url += `&ward_id=${encodeURIComponent(params.ward_id)}`;
        }
        if (params.from_date && params.from_date !== "") {
            url += `&from_date=${params.from_date}`;
        }
        if (params.to_date && params.to_date !== "") {
            url += `&to_date=${params.to_date} 23:59:59`;
        }
        if (params.status && params.status !== "") {
            url += `&status=${params.status}`;
        }

        if (permission?.Land_Acquisition?.length) {
            const departments = permission.Land_Acquisition;
            const updatedDepartments = departments.map(dept => dept.replace(/_/g, " ")).join(",");
            url += `&departments=${encodeURIComponent(updatedDepartments)}`;
        }

        Axios.get(url).then(response => {
            dispatch({
                type: 'LA_APPLICATIONS_LIST',
                allData: response.data.data.total,
                data: response.data.data.docs,
                totalPages: response.data.data.pages,
                params
            })
        }).catch(error => {
            dispatch({
                type: 'LA_APPLICATIONS_LIST',
                allData: 0,
                data: [],
                totalPages: 0,
                params
            })
        }).finally(() => {
            setIsLoader(false)
        })
    }
}


//Download Excel
export const handleExcelExport = (params, props) => {
    //new added filters
    let url = `/api/land-acquisition/export/la-applications-list?`;

    if (params.ward_id && params.ward_id !== "undefined") {
        url += `&ward_id=${encodeURIComponent(params.ward_id)}`;
    }
    if (params.from_date && params.from_date !== "") {
        url += `&from_date=${params.from_date}`;
    }
    if (params.to_date && params.to_date !== "") {
        url += `&to_date=${params.to_date} 23:59:59`;
    }

    if (params.status && params.status !== "") {
        url += `&status=${params.status}`;
    }
    return dispatch => {
        Axios.get(url, { responseType: 'blob' }).then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Land-acquisition-excel.xlsx'; // Set the desired file name
            a.click();
            URL.revokeObjectURL(url);

        })
            .catch(error => {
                console.log("LA have excel export error", error);
            })
    }
}

//------------------Get applicant data by id -----------------------//
export const getApplicantData = (id) => {
    return (dispatch) => {
        Axios.get(`api/land-acquisition/la-applicant/${id}`)
            .then((response) => {
                dispatch({
                    type: "GET_LA_APPLICATION_DATA",
                    data: response.data.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: "GET_LA_APPLICATION_DATA",
                    data: [],
                });
            });
    };
};

//------------------Get letter data ------------------------//
export const getLetterDataByName = (letterName, setOpenSendLetterDialog) => {
    
    return (dispatch) => {
        Axios.get(`api/pdf-master/get_letter?letter_type=${letterName}`)
            .then((response) => {
                dispatch({
                    type: "GET_LA_LETTER_DATA",
                    data: response.data.data,
                });
                //If letter found in PDF master then open send letter dialog
                if (response.data.data) {
                    setOpenSendLetterDialog(true);
                } else {
                    NotificationManager.error("Letter not found in PDF master");
                }
            })
            .catch((error) => {
                dispatch({
                    type: "GET_LA_LETTER_DATA",
                    data: [],
                });
            });
    };
};


export const sendLetters = (id, data, letterData, showSuccessMsg = true , handleSubmitCompensationDenied) => {
    return (dispatch) => {
        Axios.post(`/api/land-acquisition/la-transfer-logs`, {
            ...data,
            sent_by: userData?._id,
            application_id: id,
            letter_name: letterData?.letter_name,
            letter_id: letterData?._id,
        })
            .then((response) => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                } else {
                    if (showSuccessMsg) {
                        NotificationManager.success(response.data.message);
                    }
                    
                    if(data?.update_data?.dy_collector_steps?.letter_to_legal_dept && handleSubmitCompensationDenied){
                        handleSubmitCompensationDenied(false)
                    }

                    dispatch(getApplicantData(id))
                    return (dispatch) => {
                        dispatch({
                            type: 'SEND_LA_LETTER_DATA',
                            data,
                        });
                    };
                }
            })
            .catch((error) => {
                console.log('error :', error);
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'Something Went Wrong..... !'
                );
            });
    };
};

export const getInboxNotificationData = (id, department, setIsLoader, setActiveTab) => {
    setIsLoader(true)
    return dispatch => {
        let url = `api/land-acquisition/la-transfer-logs/get-all-logs?application_id=${id}&department=${department}`;
        Axios.get(url).then(response => {
            
            dispatch({
                type: 'LA_INBOX_NOTIFICATION_LIST',
                data: response.data.data,
            })
            // if (response.data.data.length === 0) {
            //     setActiveTab(1)
            // }

        }).catch(error => {
            dispatch({
                type: 'LA_INBOX_NOTIFICATION_LIST',
                data: [],
            })
        }).finally(() => {
            setIsLoader(false)
        })
    }
}


export const updateLetters = (id, data, reset) => {
    return (dispatch) => {
        Axios.put(`/api/land-acquisition/la-applicant/${id}`, data)
            .then((response) => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                } else {
                    NotificationManager.success(response.data.message);
                    // reset()
                    dispatch(getApplicantData(id))
                    return (dispatch) => {
                        dispatch({
                            type: 'UPDATE_LA_LETTER_DATA',
                            data,
                        });
                    };
                }
            })
            .catch((error) => {
                console.log('error :', error);
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'Something Went Wrong..... !'
                );
            });
    };
};

// add hearing
export const addHearing = (application_id, data, reset,setIsLoading) => {
    setIsLoading(true);
    return (dispatch) => {
        Axios.post(`/api/hearings`, {
            ...data,
            created_by: userData?._id
        })
            .then((response) => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                    setIsLoading(false);
                } else {
                    NotificationManager.success(response.data.message);
                    // reset()
                    setIsLoading(false)
                    dispatch(getHearings({
                        application_id: application_id,
                        hearing_type: data?.hearing_type
                    }));
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log('error :', error);
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'Something Went Wrong..... !'
                );
            });
    };
};

// update existing hearing
export const updateHearing = (selectedHearing, data, reset, application_id, setSelectedHearing,setIsLoading) => {
    setIsLoading(true)
    return (dispatch) => {
        Axios.put(`/api/hearings/${selectedHearing?._id}`, {
            ...data,
            // created_by: userData?._id
        })
            .then((response) => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                    setIsLoading(false);
                } else {
                    NotificationManager.success(response.data.message);
                    setIsLoading(false);
                    // reset()
                    
                    if (setSelectedHearing) {
                        setSelectedHearing(null)
                    }

                    dispatch(getHearings({
                        application_id: application_id,
                        hearing_type: selectedHearing?.hearing_type
                    }));
                }
            })
            .catch((error) => {
                console.log('error :', error);
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'Something Went Wrong..... !'
                );
            });
    }
}

// get hearing data.
export const getHearings = (data) => {
    return (dispatch) => {
        Axios.get(`/api/getLAHearings?id=${data?.application_id}&hearingType=${encodeURIComponent(data?.hearing_type)}`)
            .then((response) => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                } else {
                    if (data?.hearing_type === "Draft Notice") {
                        dispatch({
                            type: 'GET_DN_HEARINGS',
                            data: response.data.data,
                        });
                    } else {
                        dispatch({
                            type: 'GET_FA_HEARINGS',
                            data: response.data.data,
                        });
                    }
                }
            })
            .catch((error) => {
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'Something Went Wrong..... !'
                );
            });
    };
};

// add/update objection person details in hearing
export const updateHearingPersonDetails = (selectedDetailsHearing, data, reset, application_id, setSelectedDetailsHearing, setOpen, setFieldsList) => {
    return (dispatch) => {
        Axios.put(`/api/hearings-details/${selectedDetailsHearing?._id}`, {
            ...data,
            // created_by: userData?._id
        })
            .then((response) => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                } else {
                    NotificationManager.success(response.data.message);
                    reset()


                    setOpen(false);
                    setSelectedDetailsHearing(null);
                    setFieldsList([
                        { type: "Objection Person", name: "", email: "",mobileNo: "", address: "", pincode: "" }
                    ])

                    dispatch(getHearings({
                        application_id: application_id,
                        hearing_type: selectedDetailsHearing?.hearing_type
                    }));
                }
            })
            .catch((error) => {
                console.log('error :', error);
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'Something Went Wrong..... !'
                );
            });
    }
}

// complete all hearing api.
export const completeAllHearing = (application_id, data, reset,setIsLoading) => {
    setIsLoading(true);
    return (dispatch) => {
        Axios.post(`/api/complete-hearings`, {
            ...data,
            application_id: application_id,
            sent_by: userData?._id
        })
            .then((response) => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                    setIsLoading(false);
                } else {
                    NotificationManager.success(response.data.message);
                    setIsLoading(false);
                    // reset()
                    dispatch(getApplicantData(application_id))
                    dispatch(getHearings({
                        application_id: application_id,
                        hearing_type: data?.hearing_type
                    }));
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('error :', error);
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'Something Went Wrong..... !'
                );
            });
    };
};

//Download Report or letter
export const downloadReportClick = (id, name, setLoading) => {
    setLoading(true);

    return dispatch => {
        Axios.get(`/api/land-acquisition/la-transfer-logs/download-letter/${id}`, { responseType: 'blob' }).then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${name}.pdf`; // Set the desired file name
            a.click();
            URL.revokeObjectURL(url);
        })
            .catch(error => {
                console.log("download letter error", error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });
    }
}


export const LaActivityLogs = (id) => {
    return (dispatch) => {
        Axios.get(`/api/land-acquisition/la-transfer-logs/get-logs/${id}`)
            .then((response) => {
                dispatch({
                    type: "GET_LA_ACTIVITY",
                    data: response.data.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: "GET_LA_ACTIVITY",
                    data: [],
                });
            });
    };
}

// site visit notification
export const submitSiteVisitNotification = (application_id) => {
    return (dispatch) => {
        Axios.post(`/api/land-acquisition/la-applicant/site-visit-notification`, {
            application_id: application_id,
            sent_by: userData?._id
        })
            .then((response) => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                } else {
                    NotificationManager.success(response.data.message);
                    dispatch(getApplicantData(application_id))
                }
            })
            .catch((error) => {
                console.log('error :', error);
                NotificationManager.error(
                    error.response && error.response.data
                        ? error.response.data.message
                        : 'Something Went Wrong..... !'
                );
            });
    };
};

export const downloadUnsignedLetter = (data, name, setLoading) => {
    setLoading(true);

    return dispatch => {
        Axios.post(`/api/land-acquisition/la-transfer-logs/download-unsigned-letter`, data, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${name}.pdf`; // Set the desired file name
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.log("download letter error", error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });
    }
}

