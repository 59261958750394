const initialState = {
    data: [],
}

const landDefermentSetting = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_OF_LD_SETTING':
            return {
                ...state,
                data: action.data,
            }
        case 'CREATE_LD_SETTING':
            return { ...state }

        default:
            return { ...state }
    }
}
export default landDefermentSetting
