// **  Initial State
const initialState = {
    userData: {},
    isPasswordExist: false,
  
  }
  
  const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN':
        return {
          ...state,
          userData: action.data,
          isPasswordExist: action.isPasswordExist,
        }
      case 'LOGOUT':
        const obj = { ...action }
        delete obj.type
        return { ...state, userData: {}, ...obj }
  
      case 'CHANGE_PASSWORD':
        return { ...state, ...action }
  
      case 'SET_IS_PASSWORD_EXIST':
        return {
          ...state,
          isPasswordExist: action.isPasswordExist,
        };

      case 'FORGOT_PASSWORD':
        return {
          ...state,
          showPassword: action.showPassword,
        };

      case 'SET_DEVICE':
        return { 
          ...state, 
          deviceId: action.data.deviceId,
          fcmToken: action.data.fcmToken
        };
 
      default:
        return state
    }
  }
  
  export default AuthReducer
  