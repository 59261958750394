import React, { useState, useEffect } from 'react';

// Helper to detect browser name
const getBrowserName = () => {
  const userAgent = navigator.userAgent;
  if (/Chrome/.test(userAgent) && !/Edg|OPR/.test(userAgent)) {
    return 'Chrome';
  } else if (/Firefox/.test(userAgent)) {
    return 'Firefox';
  } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
    return 'Safari';
  } else if (/Edg/.test(userAgent)) {
    return 'Edge';
  } else if (/OPR/.test(userAgent) || /Opera/.test(userAgent)) {
    return 'Opera';
  }
  return 'Other';
};

// Helper to return browser-specific help link
const getBrowserHelpLink = (browserName) => {
  const links = {
    Chrome: "https://support.google.com/chrome/answer/3220216",
    Firefox: "https://support.mozilla.org/en-US/kb/push-notifications-firefox",
    Safari: "https://support.apple.com/guide/safari/manage-website-notifications-sfri40696/mac",
    Edge: "https://support.microsoft.com/en-us/microsoft-edge/manage-notification-settings-in-microsoft-edge-5c21c1a9-308b-cfb4-a201-5e023d2396ce",
    Opera: "https://help.opera.com/en/latest/web-preferences/#notifications",
    Other: "https://www.google.com/search?q=enable+browser+notifications"
  };
  return links[browserName] || links['Other'];
};

const NotificationPopup = () => {
  const [permission, setPermission] = useState('default');
  const [isVisible, setIsVisible] = useState(false);
  const [browserName, setBrowserName] = useState('Other');
  const [helpLink, setHelpLink] = useState('');

  useEffect(() => {
    // Detect if Notification API is supported
    if ('Notification' in window) {
      const currentPermission = Notification.permission;
      setPermission(currentPermission);
      if (currentPermission === 'denied') {
        // setIsVisible(true);
        setIsVisible(false);
      }
    } else {
      // If not supported, mark as unsupported and show popup with a message
      setPermission('unsupported');
      // setIsVisible(true);
      setIsVisible(false);
    }
    
    // Detect browser and help link
    const name = getBrowserName();
    setBrowserName(name);
    setHelpLink(getBrowserHelpLink(name));
  }, []);

  const openNotificationHelp = () => {
    window.open(helpLink, "_blank");
  };

  const closePopup = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div style={styles.overlay} onClick={closePopup}>
      <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
        <h3 style={styles.title}>Enable Notifications</h3>
        {permission === 'unsupported' ? (
          <p style={styles.text}>
            Your browser does not support desktop notifications.
          </p>
        ) : (
          <>
            <p style={styles.text}>
              It looks like you have blocked notifications in {browserName}.
            </p>
            <p style={styles.text}>
              To receive updates and alerts, please enable notifications in your browser settings.
            </p>
            <p style={styles.text}>
              For example, in {browserName} you can navigate to your notification settings.
            </p>
          </>
        )}
        <div style={styles.buttonContainer}>
          {permission !== 'unsupported' && (
            <button onClick={openNotificationHelp} style={styles.button}>
              Learn How to Enable Notifications
            </button>
          )}
          <button onClick={closePopup} style={styles.closeButton}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#f8d7da',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '400px',
    width: '90%',
    textAlign: 'center',
    border: '1px solid #f5c6cb',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
  },
  title: {
    margin: '0 0 12px',
    color: '#721c24',
  },
  text: {
    margin: '0 0 12px',
    color: '#721c24',
    lineHeight: '1.5',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    flexWrap: 'wrap',
  },
  button: {
    backgroundColor: '#f5c6cb',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#721c24',
    fontWeight: 'bold',
  },
  closeButton: {
    backgroundColor: '#721c24',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#fff',
    fontWeight: 'bold',
  },
};

export default NotificationPopup;
