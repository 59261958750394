const initialState = {
  data: [],
  orderCount: [],
  total: 1,
  params: {},
  cooperativeData: [],
  ctsoData: [],
  engineeringData: [],
  townPlanningData: [],
  estateData: [],
  legalData: [],
  legalHeirData: [],
  registerOfSec13Data: [],
  form14part1Data: [],
  form14part2Data: [],
  registerOfAnnexureIIData: [],
  registerOfSec33_38Data: [],
  registerOfAgreementData: [],
  paidRehabDeptData: [],
  caData: [],
  reasonForApplication: [],


  // allData: [],
  // designationData: [],
  // reportingManager: [],
  // change_PasswordData:[],
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DASHBOARD_DATA':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_COOPERATIVE_DEPT_DATA':
      return {
        ...state,
        cooperativeData: action.data,
      }
    case 'GET_ENGINEER_DEPT_DATA':
      return {
        ...state,
        engineeringData: action.data,
      }
    case 'GET_TOWN_PLANNING_DEPT_DATA':
      return {
        ...state,
        townPlanningData: action.data,
      }
    case 'GET_CTSO_DEPT_DATA':
      return {
        ...state,
        ctsoData: action.data,
      }
    case 'GET_LEGAL_DEPT_DATA':
      return {
        ...state,
        legalData: action.data,
      }
    case 'GET_ESTATE_DEPT_DATA':
      return {
        ...state,
        estateData: action.data,
      }
    case 'GET_LEGAL_HEIR_DATA':
      return {
        ...state,
        legalHeirData: action.data,
      }
    case 'GET_REGISTER_OF_SECTION_13_2_DATA':
      return {
        ...state,
        registerOfSec13Data: action.data,
      }
    case 'GET_FORM_14_1_PART_I_DATA':
      return {
        ...state,
        form14part1Data: action.data,
      }
    case 'GET_FORM_14_1_PART_II_DATA':
      return {
        ...state,
        form14part2Data: action.data,
      }
    case 'GET_REGISTER_OF_ANNEXURE_II_DATA':
      return {
        ...state,
        registerOfAnnexureIIData: action.data,
      }
    case 'GET_REGISTER_OF_SECTION_33_38_DATA':
      return {
        ...state,
        registerOfSec33_38Data: action.data,
      }
    case 'GET_REGISTER_OF_AGREEMENT_DATA':
      return {
        ...state,
        registerOfAgreementData: action.data,
      }
    case 'GET_PAID_REHAB_DEPT_DATA':
      return {
        ...state,
        paidRehabDeptData: action.data,
      }
    case 'GET_PAID_CA_DATA':
      return {
        ...state,
        caData: action.data,
      }
    case 'GET_REASON_FOR_APPLICATION':
      return {
        ...state,
        reasonForApplication: action.data,
      }

    case 'GET_INACTIVE_DEPT_DATA':
      return {
        ...state,
        inactiveDeptData: action.data,
      }

    case 'GET_INACTIVE_USER_DATA':
      return {
        ...state,
        inactiveUserData: action.data,
      }

    case 'GET_INACTIVE_DEPARTMENT_USER_DATA':
      return {
        ...state,
        inactiveDepartmentUserData: action.data,
      }

    case 'GET_BACKUP_LOG_USER_DATA':
      return {
        ...state,
        backupLogUserData: action.data,
      }


    default:
      return { ...state }
  }
}
export default dashboardReducer
