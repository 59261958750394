import React, { useEffect } from 'react' // useState
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HulkThemeProvider from './HulkThemeProvider';
import { NotificationContainer } from 'react-notifications';
import { CssBaseline } from '@material-ui/core';

import AppSignin from './Signin/SigninFirebase';
import ForgotPassword2 from './ForgotPassword2';
import SetNewPassword from './SetNewPassword';
import Otp from './Otp';
import { generateToken } from '../firebase'; // messaging

import { storeDeviceId, getDeviceId, generateDeviceId } from '../util/device';

import DefaultLayout from './DefaultLayout'

import { AsyncErrorPage404Component, AsyncErrorPage500Component, AsyncErrorPage403Component } from 'routes/ComponentLoader';

import Auth from '../Auth/Auth';

import Callback from "components/Callback/Callback";
import { setDevice, handleLogout } from 'Redux/actions/auth';
import { Axios } from '../util';

const auth = new Auth();
const handleAuthentication = ({ location }) => {
	if (/access_token|id_token|error/.test(location.hash)) {
		auth.handleAuthentication();
	}
}

const InitialPath = ({ component: Component, authUser, ...rest }) =>
	<Route
		{...rest}
		render={props =>
			authUser
				? <Component {...props} />
				: <Redirect
					to={{
						pathname: '/signin',
						state: { from: props.location }
					}}
				/>
		}
	/>;

function App(props) {
	const authUser = useSelector(state => state.authUser);

	const dispatch = useDispatch();
	const currentLocation = useLocation();

	useEffect(() => {
		let isMounted = true;

		const excludedRoutes = new Set([
			"/",
			"/signin",
			"/otp",
			"/forgot-password2",
			"/forgot-password",
			"/setNewPassword",
			"/error/404",
			"/error/403",
			"/error/500",
			"/callback",
		]);

		const checkDeviceId = async () => {
			try {
				const rawUserId = localStorage.getItem("user_id");
				if (!rawUserId) {
					console.warn("User ID not found in local storage.");
					return;
				}
				const userId = rawUserId.replace(/"/g, "");

				const response = await Axios.post(
					`/api/panel/getDeviceId`,
					{ userId }
				);
				const userDeviceId = response?.data?.data;
				if (!userDeviceId) {
					console.warn("Device ID not returned from API.");
					return;
				}
				const storedDeviceId = await getDeviceId();
				if (String(userDeviceId) !== String(storedDeviceId)) {
					dispatch(handleLogout());
				}
			} catch (error) {
				if (Axios.isCancel(error)) {
					console.log("Request canceled:", error.message);
				} else {
					console.error("Error in auth state handler:", error);
				}
			}
		};

		const initializeDeviceAndToken = async () => {
			try {
				let deviceId = await getDeviceId();
				if (!deviceId) {
					const newDeviceId = generateDeviceId();
					await storeDeviceId(newDeviceId);
					deviceId = newDeviceId;
				}
				const fcmToken = await generateToken();
				if (isMounted && deviceId && fcmToken) {
					dispatch(setDevice({ deviceId: String(deviceId), fcmToken }));
				}
			} catch (error) {
				console.error("Error initializing device and token:", error);
			}
		};

		if (process.env.REACT_APP_MULTI_USER_LOGIN === "false" && !excludedRoutes.has(currentLocation.pathname)) {
			checkDeviceId();
		}

		initializeDeviceAndToken();

		return () => {
			isMounted = false;
		};

		// eslint-disable-next-line
	}, []);

	const { user } = authUser;
	const { location, match } = props;
	if (location.pathname === "/") {
		if (user === null) {
			return (<Redirect to="/signin" />);
		} else {
			return (<Redirect to="/app/welcome" />);
		}
	}

	return (
		<HulkThemeProvider>
			<CssBaseline />
			<NotificationContainer />
			<InitialPath
				path={`${match.url}app`}
				authUser={user}
				component={DefaultLayout}
			/>
			<Route path="/signin" component={AppSignin} />
			<Route path="/otp" component={Otp} />
			<Route path="/forgot-password2" component={ForgotPassword2} />
			<Route path="/setNewPassword" component={SetNewPassword} />
			<Route path="/error/404" component={AsyncErrorPage404Component} />

			<Route path="/error/403" component={AsyncErrorPage403Component} />

			<Route path="/error/500" component={AsyncErrorPage500Component} />
			<Route path="/callback" render={(props) => {
				handleAuthentication(props);
				return <Callback {...props} />
			}} />
		</HulkThemeProvider>
	);
}

export default App;