/**
 * Main APP
 */
import React, { useEffect } from 'react'; // useRef
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { throttle } from "lodash";
import { NotificationManager } from 'react-notifications';
import { store } from './Redux/store';
import useIdleLogout from './hooks/useIdleLogout';
import { messaging } from './firebase';
// Firebase
import './firebase';

// Hulk CSS
import './lib/hulkCss';
// App Container
import App from './container/App';

// const clearLocalStorage = JSON.parse(localStorage.getItem('clearLocalStor		age'));

// if (!clearLocalStorage) {
// 	localStorage.clear();
// 	localStorage.setItem('clearLocalStorage', 'true')
// }

function MainApp() {

	const showNotification = throttle((payload) => {
		NotificationManager.success(
			payload.data.description || payload.notification.body,
			payload.data.title || payload.notification.title,
			10000
		);

		const audio = new Audio("/bell.wav");
		audio.play();
	}, 1000);

	useEffect(() => {
		messaging.onMessage((payload) => {
		  showNotification(payload); // Calls throttled function
		});
	  }, []);

	// useEffect(() => {
	// 	messaging.onMessage((payload) => {
	// 		// console.log("FG Notification: ", payload);
	// 		NotificationManager.success(payload.data.description || payload.notification.body, payload.data.title || payload.notification.title, 10000);
	// 		const audio = new Audio("/bell.wav");
	// 		audio.play();
	// 	});
	// }, [])

	// const timeoutRef = useRef(null);


	// useEffect(() => {
	// 	const unsubscribe = messaging.onMessage((payload) => {
	// 		// Set a timeout to show notification after 2 minutes
	// 		timeoutRef.current = setTimeout(() => {
	// 		NotificationManager.success(payload.data.description, payload.data.title, 10000);
	// 		const audio = new Audio("/bell.wav");
	// 		audio.play();
	// 		}, 2 * 60 * 1000); // 2 minutes delay

	// 		// Clear the timeout after 2 minutes
	// 		setTimeout(() => {
	// 			if (timeoutRef.current) {
	// 				clearTimeout(timeoutRef.current);
	// 				timeoutRef.current = null;
	// 			}
	// 		}, 2 * 60 * 1000);
	// 	});

	// 	return () => {
	// 		unsubscribe(); // Unsubscribe from messaging when component unmounts
	// 		if (timeoutRef.current) {
	// 			clearTimeout(timeoutRef.current);
	// 		}
	// 	};
	// }, []);


	useIdleLogout();
	return (
		<Provider store={store}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<Router>
					<Switch>
						<Route path="/" component={App} />
					</Switch>
				</Router>
			</MuiPickersUtilsProvider>
		</Provider>
	);
}

export default MainApp;